.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.select-container {
  width: 100%;
  text-align: center;
  padding: 1.5rem 0 1rem;
  font-size: 1.5rem;
  color: var(--orange);
  font-weight: bold;
  border-bottom: 0.125rem solid var(--orange);

  select {
    height: 3rem;
    font-size: 1.5rem;
    border-radius: 0.25rem;
    background-color: #0000;

    &:focus {
      outline: 0.125rem #ffa982 solid;
    }
  }
}

.search-container {
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  & > input {
    font-size: 1rem;
    padding-left: 0.5rem;
    width: 300px;
    height: 30px;
    border-radius: 0.25rem;
    border-radius: 0.125rem solid #000;

    &:focus {
      outline: 0.125rem #ffa982 solid;
    }
  }
}

.options-container {
  display: flex;
  margin-bottom: 0.5rem;
}

.not-found-message {
  font-size: 1rem;
  margin-top: 0.25rem;
  color: #f33;
}

.radio-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  label {
    cursor: pointer;
  }
}

.table-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1400px;
  margin: 0 2rem;

  @media screen and (max-width: 1350px) {
    max-width: 1200px;
  }

  @media screen and (max-width: 1250px) {
    max-width: 1100px;
  }

  @media screen and (max-width: 1150px) {
    max-width: 1000px;
  }

  @media screen and (max-width: 1050px) {
    max-width: 900px;
  }

  @media screen and (max-width: 950px) {
    max-width: 800px;
  }

  @media screen and (max-width: 850px) {
    max-width: 700px;
  }

  @media screen and (max-width: 750px) {
    max-width: 600px;
  }
}

.contracts-table {
  padding: 0 1rem 1rem;
  width: 100%;

  thead {
    background-color: var(--orange);
    color: #fff;

    th {
      padding: 0.5rem;
    }
  }

  tbody {
    td {
      text-align: center;
      padding: 0.25rem;
    }

    tr:nth-of-type(2n) {
      background-color: #ddd;
    }
  }
}

.row {
  position: relative;
}

.row-actions {
  background-color: #0000;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;

  &:hover {
    & > .actions {
      display: flex;
    }
  }
}

.actions {
  background-color: #fff;
  padding: 5px 0;
  border: 1px solid #ffa982;
  border-radius: 5px;
  z-index: 10;
  position: absolute;
  top: 35px;
  right: -20px;
  display: none;
  flex-direction: column;

  &::before {
    content: "";
    width: 8px;
    height: 8px;
    border-top: 1px solid #ffa982;
    border-right: 1px solid #ffa982;
    background-color: #fff;
    border-bottom-left-radius: 50%;
    position: absolute;
    top: -5px;
    left: 50%;
    transform: translateX(-100%) rotate(-45deg);
  }

  & > button {
    padding: 0.25rem 0;
    max-width: 100px;
    font-weight: bold;
    border: none;
    border: none;
    transition: all 0.2s;
    background-color: #0000;

    &:hover {
      background-color: var(--orange);
      color: #fff;
    }
  }

  & > button:not(:last-child) {
    border-bottom: 1px solid #ffa982;
  }
}

.pagination {
  list-style-type: none;
  display: flex;
  padding: 0;

  li {
    line-height: 1.5rem;
    font-weight: bold;
    color: #fff;
    font-size: 1rem;
    text-align: center;
    background-color: var(--orange);
    border-radius: 0.5rem;
    transition: opacity 0.2s;

    &:not(:last-of-type) {
      margin-right: 0.25rem;
    }

    &:not(.disabled, .selected):hover {
      opacity: 0.7;
    }

    a {
      display: block;
      height: 1.5rem;
      width: 1.5rem;
      padding: 0.25rem;
      cursor: pointer;
    }

    &.previous,
    &.next {
      font-size: 1.5rem;
    }

    &.selected {
      background-color: #ffa982;
    }

    &.disabled {
      background-color: #efefef;

      a {
        cursor: not-allowed;
      }
    }
  }
}

.no-data {
  font-size: 2rem;
}

.login-box {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -webkit-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 10px 10px 5px 0px rgba(0, 0, 0, 0.1);
  padding: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.login-input {
  padding: 5px 0 5px 10px;
  border: 1px solid #000;
  border-radius: 3px;
}

.login-button {
  margin-top: 10px;
  background-color: #ffa982;
  border: none;
  padding: 10px;
  font-weight: 700;
  border-radius: 5px;
  transition: 0.2s;

  &:active {
    transform: scale(0.9, 0.9);
  }
}
